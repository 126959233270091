<template>
    <table :class="`table`" v-for="n in tables" :key="n">
        <thead v-if="!hide_thead">
            <tr class="bg-light bg-soft">
                <th
                    :colspan="cols"
                    class="text-start "
                >
                    <div class="placeholder-wave">
                        <span :class="`placeholder col-${randomInt(3,5)}`"></span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody v-if="!hide_tbody">
            <tr
                v-for="r in rows"
                :key="r"
            >
                <td
                    class="placeholder-wave"
                    v-for="c in cols"
                    :key="c"
                >
                    <small :class="`placeholder-wave ${td_small_class}`">
                        <small :class="`placeholder col-${randomInt(3,10)}`"></small>
                    </small>
                    <div :class="`placeholder-wave ${td_div_class}`">
                        <span :class="`placeholder col-${randomInt(3,10)}`"></span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import random from '../mixins/random';
export default {
    props: {
        tables: {
            type: Number,
            required: true,
        },
        cols: {
            type: Number,
            required: true,
        },
        rows: {
            type: Number,
            required: true,
        },
        hide_thead: {
            type: Boolean,
            required: false,
        },
        hide_tbody: {
            type: Boolean,
            required: false,
        },
        td_div_class: {
            type: String,
            required: false,
        },
        td_small_class: {
            type: String,
            required: false,
        },
    },
    mixins: [random],
}
</script>

<style>
</style>