export default {
    methods: {
        formDataToObject(formData) {
            const convertedObject = {};

            for (let [key, value] of formData.entries()) {
                if (convertedObject.hasOwnProperty(key)) {
                    if (!Array.isArray(convertedObject[key])) {
                        convertedObject[key] = [convertedObject[key]];
                    }
                    convertedObject[key].push(value);
                } else {
                    convertedObject[key] = value;
                }
            }

            return convertedObject;
        },
        objectToFormData(obj, formData, parentKey) {
            formData = formData || new FormData();

            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    var propName = parentKey ? `${parentKey}[${key}]` : key;
                    var value = obj[key];
                    if (typeof value === 'object' && !(value instanceof File)) {
                        this.objectToFormData(value, formData, propName); // Recursively process nested objects
                    } else if (value instanceof FileList) {
                        for (var i = 0; i < value.length; i++) {
                            formData.append(propName, value[i]);
                        }
                    } else if (value instanceof File) {
                        formData.append(propName, value);
                    } else {
                        formData.append(propName, value);
                    }
                }
            }

            return formData;
        },
        objectToFormDataKPlay(obj, formData, parentKey) {
            formData = formData || new FormData();

            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    var propName = parentKey ? `${parentKey}[${key}]` : key;
                    var value = obj[key];
                    if (typeof value === 'object' && !(value instanceof File)) {
                        // if (obj[key]['prd_id']) {
                        //     console.log(1, obj[key]['prd_id'])
                        // }
                        this.objectToFormDataKPlay(value, formData, propName); // Recursively process nested objects
                    } else if (value instanceof FileList) {
                        for (var i = 0; i < value.length; i++) {
                            formData.append(propName, value[i]);
                        }
                    } else if (value instanceof File) {
                        formData.append(propName, value);
                    } else {
                        if (obj['prd_id']) {
                            formData.append(propName, value);
                        }
                    }
                }
            }

            return formData;
        },
        encodeNestedObject(obj, prefix = '') {
            const params = new URLSearchParams();

            for (const [key, value] of Object.entries(obj)) {
                const prefixedKey = prefix ? `${prefix}[${key}]` : key;

                if (typeof value === 'object' && value !== null) {
                    params.append(prefixedKey, this.encodeNestedObject(value, prefixedKey));
                } else {
                    params.append(prefixedKey, value);
                }
            }
            return params.toString();
        },
        formDataToUrlEncoded(formData) {
            const params = new URLSearchParams();
            for (const pair of formData) {
                params.append(pair[0], pair[1]);
            }
            return params.toString();
        },
        convertObjectToArray(inputObject) {
            return Object.entries(inputObject).map(([field, errors]) => {
                const fieldName = field.includes('.') ? field.split('.')[1] : field;
                return {
                    field: fieldName,
                    errors
                };
            });
        }
    }
}