import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
        return {
            pressTimer: null,
            scrollStep: 25
        }
    },
    computed: {
        ...mapGetters('auth', ['profile']),
        client_name() {
            return process.env.VUE_APP_CLIENT_NAME;
        },
        player_mode() {
            return process.env.VUE_APP_PLAYER_MODE;
        },
        allowed() {
            return Boolean(!this.profile.readonly)
        }
    },
    methods: {
        ...mapActions('auth', {
            authGetProfile: 'getProfile'
        }),
        isAuthorized() {
            if (this.profile.readonly) {
                Swal.fire({
                    title: this.$t('unauthorized'),
                    icon: 'warning',
                    timer: 2000,
                    confirmButtonText: this.$t('ok')
                });
                return false
            }
            return true
        },
        resetScroll(scrollLeft) {
            const el = this.$refs.tableScroller
            if (!el) return
            el.scrollTo({ top: 0, behavior: "instant" });
            setTimeout(() => {
                el.scrollTo({ left: scrollLeft ? scrollLeft : 0, behavior: "instant" });
            }, 100)
        },
        startScrolling(direction) {
            // The interval for scrolling the table (in milliseconds)
            const scrollInterval = 10; // Adjust this value to change the scrolling smoothness
            this.pressTimer = setInterval(() => {

                const el = this.$refs.tableScroller
                switch (direction) {
                    case 'up':
                        el.scrollTop -= this.scrollStep;
                        break;
                    case 'right':
                        el.scrollLeft += this.scrollStep;
                        break;
                    case 'down':
                        el.scrollTop += this.scrollStep;
                        break;
                    case 'left':
                        el.scrollLeft -= this.scrollStep;
                        break;
                }
            }, scrollInterval);

        },
        endScrolling() {
            clearInterval(this.pressTimer);
        },
        goToUserForm(id, role) {
            window.open(
                `/${role}/form${id ? '?id=' + id : ''}`,
                `${role}-form-${id}`,
                'width=' + 1400 + ',height=' + (screen.height)
            );
        },
        goToBatchCreateForm() {
            window.open(
                `/player/batch-form`,
                `player-batch-form`,
                'width=' + 700 + ',height=' + 550
            );
        },
        goToTicketView(id) {
            window.open(
                `/ticket/view/${id}`,
                'ticket-view',
                'width=' + 1020 + ',height=' + 515
            );
            this.$store.commit('removeNotificationById', id);
        },
        goToTicketForm(username, role) {
            window.open(
                `/ticket/form/${role}${username ? '?username=' + username : ''}`,
                '/ticket/form',
                'width=' + 1020 + ',height=' + 550
            );
        },
        goToAssetForm(id, asset) {
            window.open(
                `/player/${asset}/${id}`,
                'asset-form',
                'width=' + 500 + ',height=' + 385
            );
        },
    }
}