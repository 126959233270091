export default {
    methods: {
        randomCouponCode(min, max) {
            var alphanumeric = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
            var code = ""
            for (var i = 0; i < 9; i++) {
                if (i == 4) {
                    code += '-'
                } else {
                    code += alphanumeric[Math.floor(Math.random() * alphanumeric.length)]
                }
            }
            return code
        },
        randomCode(min, max) {
            var characters = "abcdefghijklmnopqrstuvwxyz0123456789"
            var length = Math.floor(Math.random() * (max - min + 1)) + min;
            var code = ""
            for (var i = 0; i < length; i++) {
                code += characters[Math.floor(Math.random() * characters.length)]
            }
            return code
        },
        randomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        randomDate(start, end) {
            return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        },
        randomAccountNumber() {
            // 0000[5]0000[]0000[13]00
            var number = ""
            for (var i = 0; i < 17; i++) {
                if ([4, 9, 14].includes(i)) {
                    number += ` `
                } else {
                    number += `${Math.floor(Math.random() * 10)}`
                }
            }
            return number
        },
        randomNames() {
            const names = [
                "John Smith",
                "Emma Johnson",
                "Michael Williams",
                "Sophia Brown",
                "William Davis",
                "Olivia Jones",
                "James Miller",
                "Ava Wilson",
                "Benjamin Anderson",
                "Isabella Martinez",
                "Jacob Taylor",
                "Mia Thomas",
                "Liam Clark",
                "Charlotte Rodriguez",
                "Elijah Lee",
                "Amelia Martinez",
                "Lucas Scott",
                "Harper Phillips",
                "Alexander Turner",
                "Evelyn Hall"
            ];


            return names[Math.floor(Math.random() * names.length)]
        },
        randomTransactionType() {

            return Math.floor(Math.random() * 2 + 1)
        },
    }
}